<template>
  <div id="centerRight1">
    <div class="top-header"><div class="chaxun" @click="changeBtn"></div></div>
    <div class="box-pie">
      <rightTop :cata="data1" />
      <div class="center-circle">
        <div class="word">装备总数</div>
        <div class="num">{{ dataNum }}</div>
      </div>
    </div>
    <div class="box-bottom">
      <div
        class="bottom-one"
        v-for="item in dataList"
        :key="item.deptName"
        @click="getEquipDetail(item.deptId)">
        <div class="name" :title="item.deptName">{{ item.deptName }}</div>
        <div class="progress">
          <el-progress
            :percentage="getPercentage(item.totalNum)"
            :show-text="false"></el-progress>
        </div>
        <div>
          {{ item.totalNum }}
        </div>
      </div>
    </div>
    <el-dialog
      title="装备情况"
      :visible.sync="openInfo"
      width="60%"
      custom-class="dialog-zb"
      append-to-body>
      <div class="dialog-content">
        <div class="zongshu">{{ detailDataList.totalNum }}台套</div>
        <div class="list">
          <div
            class="list-one"
            v-for="item in detailDataList.dataList"
            :key="item.name">
            <div class="title">
              <div class="title-left"></div>
              <div class="title-word">{{ item.name + item.num }}台套</div>
            </div>
            <div class="children-list">
              <div
                class="bottom-one"
                v-for="i in item.children"
                :key="i.name"
                @click="zbDialog(item, i)">
                <div class="name" :title="i.name">{{ i.name }}</div>
                <div class="progress">
                  <el-progress
                    :percentage="(i.num / item.num) * 100"
                    :show-text="false"></el-progress>
                </div>
                <div>
                  {{ i.num }}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- <div slot="footer" class="dialog-footer">
        <el-button @click="openInfo = false">关 闭</el-button>
      </div> -->
    </el-dialog>
    <!-- 穿透 -->
    <el-dialog
      :title="chuantouTitle"
      :visible.sync="chuantouInfo"
      width="60%"
      @close="closeListDialog"
      custom-class="dialog-list"
      append-to-body>
      <el-table
        :data="dialogTableData"
        style="width: 100%"
        stripe
        height="540"
        :header-cell-style="{
          background: '#3c598c',
          color: 'rgb(220, 231, 255);'
        }">
        <el-table-column align="center" prop="equipmentName" label="名称">
        </el-table-column>
        <el-table-column align="center" prop="deptName" label="部门">
        </el-table-column>
        <el-table-column align="center" prop="equipmentModel" label="型号">
        </el-table-column>
        <el-table-column align="center" prop="largeType" label="大类">
        </el-table-column>
        <el-table-column align="center" prop="mediumType" label="中类">
        </el-table-column>
        <el-table-column align="center" prop="smallType" label="小类">
        </el-table-column>
        <el-table-column align="center" prop="number" label="数量">
        </el-table-column>
        <el-table-column align="center" prop="measureUnit" label="计量单位">
        </el-table-column>
        <el-table-column align="center" prop="productCompany" label="厂商">
        </el-table-column>
        <el-table-column align="center" prop="equipDate" label="装备日期">
        </el-table-column>
        <el-table-column align="center" prop="technicalStatus" label="技术状况">
        </el-table-column>
        <el-table-column align="center" prop="useInfo" label="使用情况">
        </el-table-column>
      </el-table>
      <pagination
        v-show="total > 0"
        :total="total"
        :page.sync="queryParams.pageNum"
        :limit.sync="queryParams.pageSize"
        @pagination="getEquipInfo()" />
    </el-dialog>

    <!-- 按钮 -->
    <el-dialog
      title="装备详情"
      custom-class="dialog-list"
      :visible.sync="dialogVisibleBtn"
      width="60%"
      @close="closeBtnDialog"
      append-to-body>
      <el-form
        :model="queryParamsBtn"
        ref="queryForm"
        size="small"
        :inline="true"
        label-width="50px">
        <el-form-item label="部门" prop="deptName">
          <el-select
            v-model="queryParamsBtn.deptName"
            placeholder="请选择"
            :popper-append-to-body="false">
            <el-option
              v-for="item in pianquOptions"
              :key="item.deptName"
              :label="item.deptName"
              :value="item.deptName">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="大类" prop="largeType">
          <el-select
            v-model="queryParamsBtn.largeType"
            placeholder="请选择"
            :popper-append-to-body="false">
            <el-option
              v-for="item in daleiOptions"
              :key="item.largeType"
              :label="item.largeType"
              :value="item.largeType">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="中类" prop="mediumType">
          <el-select
            v-model="queryParamsBtn.mediumType"
            placeholder="请选择"
            :popper-append-to-body="false">
            <el-option
              v-for="item in zhongleiOptions"
              :key="item.mediumType"
              :label="item.mediumType"
              :value="item.mediumType">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="小类" prop="smallType">
          <el-select
            v-model="queryParamsBtn.smallType"
            placeholder="请选择"
            :popper-append-to-body="false">
            <el-option
              v-for="item in xiaoleiOptions"
              :key="item.smallType"
              :label="item.smallType"
              :value="item.smallType">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item>
          <el-button
            type="primary"
            icon="el-icon-search"
            size="mini"
            @click="handleQuery"
            >搜索</el-button
          >
          <el-button
            type="primary"
            icon="el-icon-refresh"
            size="mini"
            @click="resetQuery"
            >重置</el-button
          >
        </el-form-item>
      </el-form>
      <el-table
        :data="btnDialogTableData"
        style="width: 100%"
        stripe
        height="475"
        :header-cell-style="{
          background: '#3c598c',
          color: 'rgb(220, 231, 255);'
        }">
        <el-table-column align="center" prop="equipmentName" label="名称">
        </el-table-column>
        <el-table-column align="center" prop="deptName" label="部门">
        </el-table-column>
        <el-table-column align="center" prop="equipmentModel" label="型号">
        </el-table-column>
        <el-table-column align="center" prop="largeType" label="大类">
        </el-table-column>
        <el-table-column align="center" prop="mediumType" label="中类">
        </el-table-column>
        <el-table-column align="center" prop="smallType" label="小类">
        </el-table-column>
        <el-table-column align="center" prop="number" label="数量">
        </el-table-column>
        <el-table-column align="center" prop="measureUnit" label="计量单位">
        </el-table-column>
        <el-table-column align="center" prop="productCompany" label="厂商">
        </el-table-column>
        <el-table-column align="center" prop="equipDate" label="装备日期">
        </el-table-column>
        <el-table-column align="center" prop="technicalStatus" label="技术状况">
        </el-table-column>
        <el-table-column align="center" prop="useInfo" label="使用情况">
        </el-table-column>
      </el-table>
      <pagination
        v-show="BtnTotal > 0"
        :total="BtnTotal"
        :page.sync="queryParamsBtn.pageNum"
        :limit.sync="queryParamsBtn.pageSize"
        @pagination="getEquipList()" />
    </el-dialog>
  </div>
</template>

<script>
import rightTop from '@/components/echart/right/rightTop'
import {
  equipTotal,
  equipDetail,
  equipInfo,
  equipDeptName,
  equipMeduimType,
  equipLargeType,
  equipSmallType,
  equipList
} from '../api/test'

export default {
  components: {
    rightTop
  },
  props: {
    // dataInfo: {
    //   type: Object,
    //   default: () => {}
    // }
  },
  data() {
    return {
      total: 44,
      // 查询参数
      queryParams: {
        pageNum: 1,
        pageSize: 10,
        deptId: null,
        largeType: null,
        smallType: null
      },
      chuantouTitle: '',
      chuantouInfo: false,
      data1: null,
      dataList: [
        { deptName: '本部及dadadnannnnnnnnnnnnn基地1', totalNum: 200 },
        { deptName: '本部及dadadnannnnnnnnnnnnn基地12', totalNum: 200 },
        { deptName: '本部及dadadnannnnnnnnnnnnn基地13', totalNum: 200 },
        { deptName: '本部及dadadnannnnnnnnnnnnn基地14', totalNum: 200 },
        { deptName: '本部及dadadnannnnnnnnnnnnn基地15', totalNum: 200 },
        { deptName: '本部及dadadnannnnnnnnnnnnn基地16', totalNum: 200 }
        // { deptName: '本部及dadadnannnnnnnnnnnnn基地17', totalNum: 200 }
        // { deptName: '华东片区1', totalNum: 100 }
        // { deptName: '华东片区2', totalNum: 100 },
        // { deptName: '华东片区3', totalNum: 100 },
        // { deptName: '华东片区4', totalNum: 100 },
        // { deptName: '华东片区5', totalNum: 100 },
        // { deptName: '华东片区6', totalNum: 100 },
        // { deptName: '华东片区7', totalNum: 100 },
        // { deptName: '华东片区21', totalNum: 150 },
        // { deptName: '华东片区32', totalNum: 20 },
        // { deptName: '华东片区53', totalNum: 30 }
      ],
      detailDataList: {
        totalNum: 5000,
        dataList: [
          {
            name: '运输类',
            num: 500,
            children: [
              {
                name: '拖eeeeeeeeeeeeeeeeeeeeeeee水水水顶顶顶水水水车(台套)',
                num: 200
              },
              {
                name: '拖eeeeeeeeeeeeeeeeeeeeeeee水水水顶顶顶水水水车(台套)',
                num: 200
              },
              {
                name: '拖eeeeeeeeeeeeeeeeeeeeeeee水水水顶顶顶水水水车(台套)',
                num: 200
              },
              {
                name: '拖eeeeeeeeeeeeeeeeeeeeeeee水水水顶顶顶水水水车(台套)',
                num: 200
              },
              {
                name: '拖eeeeeeeeeeeeeeeeeeeeeeee水水水顶顶顶水水水车(台套)',
                num: 200
              },
              {
                name: '拖eeeeeeeeeeeeeeeeeeeeeeee水水水顶顶顶水水水车(台套)',
                num: 200
              },
              {
                name: '拖eeeeeeeeeeeeeeeeeeeeeeee水水水顶顶顶水水水车(台套)',
                num: 200
              },
              {
                name: '拖eeeeeeeeeeeeeeeeeeeeeeee水水水顶顶顶水水水车(台套)',
                num: 200
              },
              { name: '吊车(台套)', num: 100 },
              { name: '吊车(台套)', num: 100 },
              { name: '吊车(台套)', num: 100 },
              { name: '吊车(台套)', num: 100 },
              { name: '吊车(台套)', num: 100 },
              { name: '吊车(台套)', num: 100 },
              { name: '大巴车(台套)', num: 150 },
              { name: '拖车(台套)1', num: 20 },
              { name: '拖车(台套)2', num: 30 }
            ]
          },
          {
            name: '运输类11',
            num: 500,
            children: [
              { name: '拖车(台套)', num: 200 },
              { name: '吊车(台套)', num: 100 },
              { name: '大巴车(台套)', num: 150 },
              { name: '拖车(台套)1', num: 20 },
              { name: '拖车(台套)2', num: 30 }
            ]
          },
          {
            name: '运输类2',
            num: 500,
            children: [
              { name: '拖车(台套)', num: 200 },
              { name: '吊车(台套)', num: 100 },
              { name: '大巴车(台套)', num: 150 },
              { name: '拖车(台套)1', num: 20 },
              { name: '拖车(台套)2', num: 30 }
            ]
          },
          {
            name: '运输类3',
            num: 500,
            children: [
              { name: '拖车(台套)', num: 200 },
              { name: '吊车(台套)', num: 100 },
              { name: '大巴车(台套)', num: 150 },
              { name: '拖车(台套)1', num: 20 },
              { name: '拖车(台套)2', num: 30 }
            ]
          },
          {
            name: '运输类4',
            num: 600,
            children: [
              { name: '拖车(台套)', num: 300 },
              { name: '吊车(台套)', num: 100 },
              { name: '大巴车(台套)', num: 150 },
              { name: '拖车(台套)1', num: 20 },
              { name: '拖车(台套)2', num: 30 }
            ]
          }
        ]
      },
      dataNum: 300,
      openInfo: false,
      // 选择框
      selectOptions: [
        {
          value: '选项1',
          label: '黄金糕'
        },
        {
          value: '选项2',
          label: '双皮奶'
        },
        {
          value: '选项3',
          label: '蚵仔煎'
        },
        {
          value: '选项4',
          label: '龙须面'
        },
        {
          value: '选项5',
          label: '北京烤鸭'
        }
      ],
      dialogTableData: [
        {
          name: '设备1',
          bm: 'a',
          xh: 'a',
          sl: 'a',
          cs: 'a',
          zt: 'a'
        },
        {
          name: '设备2',
          bm: 'a',
          xh: 'a',
          sl: 'a',
          cs: 'a',
          zt: 'a'
        },
        {
          name: '设备3',
          bm: 'a',
          xh: 'a',
          sl: 'a',
          cs: 'a',
          zt: 'a'
        }
      ],
      deptId: null,
      largeType: null,
      smallType: null,
      dialogVisibleBtn: false,
      btnDialogTableData: [],
      queryParamsBtn: {
        pageNum: 1,
        pageSize: 10,
        deptId: null,
        largeType: null,
        smallType: null
      },
      BtnTotal: 0,
      pianquOptions: [],
      daleiOptions: [],
      zhongleiOptions: [],
      xiaoleiOptions: []
    }
  },
  mounted() {
    this.getEquipTotal()
  },
  methods: {
    // 按钮
    changeBtn() {
      this.dialogVisibleBtn = true
      this.getClassify()
      this.getEquipLargeType()
      this.getEquipMeduimType()
      this.getEquipSmallType()
      this.getEquipList()
    },
    // 部门下拉
    getClassify() {
      equipDeptName().then((res) => {
        this.pianquOptions = res.data.data
      })
    },
    // 大类下拉
    getEquipLargeType() {
      equipLargeType().then((res) => {
        this.daleiOptions = res.data.data
      })
    },
    // 中类下拉
    getEquipMeduimType() {
      equipMeduimType().then((res) => {
        this.zhongleiOptions = res.data.data
      })
    },
    // 小类下拉
    getEquipSmallType() {
      equipSmallType().then((res) => {
        this.xiaoleiOptions = res.data.data
      })
    },
    // 装备弹窗列表
    getEquipList() {
      equipList(this.queryParamsBtn).then((res) => {
        // console.log(res, 'pppp')
        if (res.data.rows) {
          this.btnDialogTableData = res.data.rows
          this.BtnTotal = res.data.total
        }
      })
    },
    //装备弹窗列表搜索
    handleQuery() {
      this.queryParamsBtn.pageNum = 1
      this.getEquipList()
    },
    resetQuery() {
      this.queryParamsBtn = {
        pageNum: 1,
        pageSize: 10,
        deptId: null,
        largeType: null,
        smallType: null
      }
      this.getEquipList()
    },
    // 关闭按钮弹窗
    closeBtnDialog() {
      this.queryParamsBtn.pageNum = 1
      this.dialogVisibleBtn = false
    },
    // 关闭按钮弹窗
    closeListDialog() {
      this.queryParams.pageNum = 1
      this.chuantouInfo = false
    },

    getPercentage(num) {
      return (num / this.dataNum) * 100
    },

    getEquipTotal() {
      equipTotal().then((res) => {
        this.dataList = res.data.data
        res.data.data.forEach((e) => {
          this.dataNum += e.totalNum
        })
        // this.data1 = this.dataList.map((e) => {
        //   return {
        //     name: e.deptName,
        //     value: e.totalNum
        //   }
        // })
        // console.log(this.data, 'pppp')
      })
    },
    getEquipDetail(id) {
      this.openInfo = true
      this.deptId = id
      equipDetail({ deptId: id }).then((res) => {
        this.openInfo = true
        this.detailDataList = res.data.data
      })
    },
    getEquipInfo() {
      this.queryParams.largeType = this.largeType
      this.queryParams.smallType = this.smallType
      this.queryParams.deptId = this.deptId
      equipInfo(this.queryParams).then((res) => {
        this.dialogTableData = res.data.rows
        this.total = res.data.total
      })
    },
    //穿透点击
    zbDialog(item, i) {
      this.chuantouTitle = item.name
      this.chuantouInfo = true
      this.dialogTableData = []
      this.largeType = item.name
      this.smallType = i.name
      this.getEquipInfo()
    }
  }
}
</script>

<style lang="scss" scoped>
$box-width: 443px;
$box-height: 475px;
#centerRight1 {
  // padding: 16px;
  // padding-top: 20px;
  height: $box-height;
  width: $box-width;
  // background-color: red;
  .top-header {
    position: relative;
    width: 433px;
    height: 48px;
    background-image: url('../assets/images/zbheader.svg');
    background-size: 100% 100%;
    font-size: 20px;
    padding-left: 25px;
    padding-top: 9px;
    // margin-bottom: 20px;
    & > span {
      background-image: -webkit-linear-gradient(bottom, #f9fbfe, #afe4fe);
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
      // font-weight: 600;
    }
    .chaxun {
      width: 60px;
      height: 34px;
      cursor: pointer;
      // border: 1px solid red;
      background-image: url('../assets/images/btn.svg');
      background-size: 100% 100%;
      position: absolute;
      right: 5px;
      top: 0px;
    }
  }
  .box-pie {
    position: relative;
    width: 100%;
    // height: 200px;
    // margin-top: 20px;
    // background: red;
    .center-circle {
      position: absolute;
      width: 125px;
      height: 125px;
      border-radius: 50%;
      border: 1px solid #294c71;
      background-color: #112a48;
      top: 63px;
      left: 115px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      z-index: -1;
      .word {
        color: rgba(255, 255, 255, 0.7);
      }
      .num {
        font-size: 20px;
        margin-top: 11px;
      }
    }
  }
  .box-bottom {
    display: flex;
    flex-direction: column;
    // align-items: ;
    justify-content: space-between;
    width: 100%;
    height: 180px;
    overflow: auto;
    // background-color: #fff;
    .bottom-one {
      display: flex;
      align-items: center;
      cursor: pointer;
      color: #fff;

      // margin-top: 28px;
      .name {
        width: 100px;
        text-align: right;
        margin-right: 8px;
        color: rgba(176, 202, 232, 1);
        overflow: hidden; // 溢出隐藏
        white-space: nowrap; // 强制一行
        text-overflow: ellipsis; // 文字溢出显示省略号
      }
      .progress {
        margin-right: 8px;
        .el-progress {
          width: 300px;
        }
      }
    }
    // background-image: -webkit-linear-gradient(180deg, #a8e5fb 0%, #e6f8ff 100%);
    // -webkit-background-clip: text;
    // -webkit-text-fill-color: transparent;
  }
}
::v-deep .dialog-zb {
  // background-image: url('../assets/tcbg.png');
  background-size: 100% 100%;
  background-color: #0e2241;
  width: 1105px !important;
  height: 694px !important;
  margin-top: 10vh !important;
  .el-dialog__title {
    color: #fff;
  }
  .el-dialog__header {
    padding: 0 0 0 58px;
    height: 50px;
    line-height: 50px;

    background-image: url('../assets/images/list-top.svg');
    background-repeat: no-repeat;
    background-position: 20px 10px;
    // background-size: 100% 100%;
    background-color: #2a4e93;
  }
  .el-dialog__body {
    padding: 20px 38px;
    height: calc(100% - 55px);
    overflow: auto;
  }
  .dialog-content {
    .zongshu {
      font-size: 16px;
      color: #fff;
    }
    .list {
      display: flex;
      flex-wrap: wrap;
      margin-top: 15px;

      .list-one {
        width: 510px;
        // background-color: green;
        margin-bottom: 32px;

        .title {
          display: flex;
          align-items: center;
          color: #fff;
          .title-left {
            width: 2px;
            height: 21px;
            background-color: #fff;
            margin-right: 11px;
          }
          .title-word {
            font-size: 18px;
          }
        }
        .children-list {
          margin-top: 25px;
          height: 220px;
          display: flex;
          flex-direction: column;
          overflow: auto;

          // align-items: ;
          justify-content: space-between;
          .bottom-one {
            display: flex;
            align-items: center;
            color: #fff;
            cursor: pointer;
            // margin-top: 28px;
            .name {
              width: 125px;
              text-align: right;
              margin-right: 8px;
              color: rgba(176, 202, 232, 1);
              overflow: hidden; // 溢出隐藏
              white-space: nowrap; // 强制一行
              text-overflow: ellipsis; // 文字溢出显示省略号
            }
            .progress {
              margin-right: 8px;
              .el-progress {
                width: 320px;
              }
            }
          }
        }
      }
      .list-one:nth-last-child(1),
      .list-one:nth-last-child(2) {
        margin-bottom: 0;
      }
    }
  }
}
.children-list::-webkit-scrollbar {
  width: 6px;
  height: 50px;
  background-color: rgb(183, 210, 241);
}
.children-list::-webkit-scrollbar-thumb {
  border-radius: 10px;
  // -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  background-color: #00aaff;
}
/* 渐变进度条 */
::v-deep .el-progress-bar__inner {
  border-radius: 0;
  background: linear-gradient(
    270deg,
    #00aaff 1.1%,
    rgba(0, 170, 255, 0.1) 100%
  );
}

::v-deep .el-progress-bar__outer {
  background-color: rgba(255, 255, 255, 0.08) !important;
  border-radius: 0;
}
::v-deep .el-dialog__body::-webkit-scrollbar {
  width: 6px;
  height: 50px;
  background-color: rgb(183, 210, 241);
}
::v-deep .el-dialog__body::-webkit-scrollbar-thumb {
  border-radius: 10px;
  background-color: #00aaff;
}
.box-bottom::-webkit-scrollbar {
  width: 6px;
  height: 50px;
  background-color: rgb(183, 210, 241);
}
.box-bottom::-webkit-scrollbar-thumb {
  border-radius: 10px;
  background-color: #00aaff;
}

/////////////////////////////////////////////////@at-root
::v-deep .el-table .el-table__cell {
  padding: 9px 0 !important;
}
::v-deep .el-table,
::v-deep .el-table__expanded-cell {
  background-color: transparent;
}
/* 表格内背景颜色 */
::v-deep .el-table th,
::v-deep .el-table tr,
::v-deep .el-table td {
  color: rgb(183, 210, 241);
  background-color: transparent;
}
::v-deep .el-table tr {
  background-color: #131e34;
  // background-color: red;
}
// 隔行变色
::v-deep .el-table--striped {
  .el-table__body tr.el-table__row--striped td.el-table__cell {
    background-color: #0d172a;
    // background-color: red;
  }
}
::v-deep .el-table td.el-table__cell,
.el-table th.el-table__cell.is-leaf {
  border-bottom: 1px solid #243a64;
}
// hover
::v-deep
  .el-table--enable-row-hover
  .el-table__body
  tr:hover
  > td.el-table__cell {
  background-color: unset;
}
::v-deep.el-table--scrollable-y .el-table__body-wrapper::-webkit-scrollbar {
  width: 6px;
  height: 50px;
  background-color: rgb(183, 210, 241);
}
::v-deep.el-table--scrollable-y
  .el-table__body-wrapper::-webkit-scrollbar-thumb {
  border-radius: 10px;
  // -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  background-color: #00aaff;
}

///@at-root
///
///
::v-deep .el-form-item__label {
  color: rgb(183, 210, 241);
}

//@at-root
::v-deep .el-input__inner {
  border: 1px solid #2094fb;
  // border: 1px solid red;
  border-radius: 0;
  background-color: #12253d;
  color: #fff;
}
::v-deep .el-select-dropdown {
  background-color: #12253d !important;
  border: 0;
}
::v-deep .el-select-dropdown__item {
  color: #fff;
}
::v-deep .el-select-dropdown__item.selected {
  color: #afe4fe;
}
::v-deep .el-select-dropdown__item.hover,
.el-select-dropdown__item:hover {
  background-color: #2094fb;
}
::v-deep .el-popper[x-placement^='bottom'] .popper__arrow::after {
  background-color: #12253d;
}
::v-deep .dialog-list {
  background-color: #0e2241;
  width: 75% !important;
  height: 694px !important;
  margin-top: 10vh !important;
  .el-dialog__title {
    color: #fff;
  }
  .el-dialog__header {
    padding: 0 0 0 58px;
    height: 50px;
    line-height: 50px;

    background-image: url('../assets/images/list-top.svg');
    background-repeat: no-repeat;
    background-position: 20px 10px;
    // background-size: 100% 100%;
    background-color: #2a4e93;
  }
  .el-dialog__body {
    padding: 20px 38px;
    height: calc(100% - 55px);
    overflow: auto;
    .el-table .el-table__cell.gutter {
      background: #3c598c !important;
    }
  }
}
</style>
<style lang="scss"></style>
