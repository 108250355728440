<template>
  <div>
    <Echart
      :options="options"
      id="rightTop"
      height="250px"
      width="100%"></Echart>
  </div>
</template>

<script>
import Echart from '@/common/echart'
import { materialTota } from '@/api/test'

export default {
  data() {
    return {
      options: {},
      data1: []
    }
  },
  components: {
    Echart
  },
  props: {
    cdata: {
      type: Object,
      default: () => ({})
    }
  },
  watch: {
    data1: {
      handler(data) {
        // console.log('111')
        // let data = [
        //   { value: 1048, name: '本部及基地' },
        //   { value: 735, name: '华东片区' },
        //   { value: 580, name: '西南片区' },
        //   { value: 484, name: '西北片区' },
        //   { value: 300, name: '中部片区' }
        // ]
        this.options = {
          tooltip: {
            trigger: 'item'
          },
          grid: {
            bottom: 100
          },
          legend: {
            orient: 'vertical',
            itemHeight: 8,
            itemWidth: 8,
            itemGap: 25, //图例之间的间距
            left: '80%',
            x: 'left', //可设定图例在左、右、居中
            y: 'center', //可设定图例在上、下、居中
            padding: [0, 50, 0, 0] //可设定图例[距上方距离，距右方距离，距下方距离，距左方距离]
            // formatter: function (name) {
            //   console.log(name)
            //   let singleData = data.filter(function (item) {
            //     return item.name == name
            //   })
            //   return name + '\n\n\t\t\t' + singleData[0].value + ''
            // }
          },
          color: [
            '#e9bd4b',
            '#68c9d3',
            '#4d97f1',
            'rgb(156, 190, 207)',
            '#cde3fb'
          ],
          series: [
            {
              left: '-20%',
              name: '物资情况',
              type: 'pie',
              radius: ['50%', '65%'],
              itemStyle: {
                borderRadius: 20,
                borderColor: '#061830',
                borderWidth: 5
              },
              label: {
                normal: {
                  formatter: function (params) {
                    return params.percent + '%'
                  },
                  textStyle: {
                    // 提示文字的样式
                    color: '#fff',
                    fontSize: 14
                  }
                }
              },
              startAngle: 180,
              data: data
            }
          ]
        }
      },
      immediate: true,
      deep: true
    }
  },
  mounted() {
    this.getEquipTotal()
  },
  methods: {
    getEquipTotal() {
      materialTota().then((res) => {
        this.data1 = res.data.data.map((e) => {
          return {
            name: e.deptName,
            value: e.totalNum
          }
        })
      })
    }
  }
}
</script>

<style lang="scss" scoped></style>
