<template>
  <div id="centerLeft1">
    <div class="top-header">
      <!-- <span>人员情况</span> -->
      <!-- <img src="../assets/images/btn.svg" alt="" /> -->
      <div class="chaxun" @click="changeBtn"></div>
    </div>
    <div class="people-tongji">
      <div class="people-img">
        <img src="../assets/people_img.svg" alt="" width="106" />
      </div>
      <div class="people-tongji-right">
        <div class="right-top">
          <img src="../assets/a1.svg" alt="" />
          <img src="../assets/a2.svg" alt="" class="position-img" />
          <div class="position-word">人数统计</div>
        </div>
        <div class="right-bottom">
          <span class="the-word">总人数值：</span>
          <span class="the-num">{{ allPeople }}</span>
        </div>
      </div>
    </div>
    <!-- default-expand-all -->

    <div class="bottom-table">
      <el-table
        :data="tableData"
        style="width: 100%; margin-bottom: 20px"
        row-key="id"
        stripe
        height="760"
        @row-click="handleSeleUserId"
        :header-cell-style="{
          background: '#284489',
          color: '#AFBED8'
        }"
        :tree-props="{ children: 'children', hasChildren: 'hasChildren' }">
        <el-table-column
          prop="typeName"
          label="类型"
          width="145"
          show-overflow-tooltip>
        </el-table-column>
        <el-table-column prop="totalNum" label="总人数" width="" align="center">
        </el-table-column>
        <el-table-column prop="onNum" label="在位数" align="center">
        </el-table-column>
        <el-table-column prop="offNum" label="离位数" align="center">
        </el-table-column>
      </el-table>
    </div>
    <!-- 点击按钮 -->
    <el-dialog
      title="人员详情"
      custom-class="dialog-list"
      :visible.sync="dialogVisibleBtn"
      width="60%"
      @close="closeBtnDialog"
      append-to-body>
      <el-form
        :model="queryParamsBtn"
        ref="queryForm"
        size="small"
        :inline="true"
        label-width="50px">
        <el-form-item label="单位" prop="deptName">
          <el-select
            v-model="queryParamsBtn.deptName"
            placeholder="请选择"
            :popper-append-to-body="false">
            <el-option
              v-for="item in pianquOptions"
              :key="item.deptName"
              :label="item.deptName"
              :value="item.deptName">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="分类" prop="userType">
          <el-select
            v-model="queryParamsBtn.userType"
            placeholder="请选择"
            :popper-append-to-body="false">
            <el-option
              v-for="item in daleiOptions"
              :key="item.userType"
              :label="item.userType"
              :value="item.userType">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="工种" prop="userJob">
          <el-select
            v-model="queryParamsBtn.userJob"
            placeholder="请选择"
            :popper-append-to-body="false">
            <el-option
              v-for="item in xiaoleiOptions"
              :key="item.userJob"
              :label="item.userJob"
              :value="item.userJob">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item>
          <el-button
            type="primary"
            icon="el-icon-search"
            size="mini"
            @click="handleQuery"
            >搜索</el-button
          >
          <el-button
            type="primary"
            icon="el-icon-refresh"
            size="mini"
            @click="resetQuery"
            >重置</el-button
          >
        </el-form-item>
      </el-form>
      <el-table
        :data="btnDialogTableData"
        style="width: 100%"
        stripe
        height="475"
        :header-cell-style="{
          background: '#3c598c',
          color: 'rgb(220, 231, 255);'
        }">
        <el-table-column align="center" prop="userName" label="姓名">
        </el-table-column>
        <el-table-column align="center" prop="deptName" label="部门">
        </el-table-column>
        <el-table-column align="center" prop="userPosition" label="职务职级">
        </el-table-column>
        <el-table-column
          align="center"
          prop="userPositionDate"
          label="任职时间">
        </el-table-column>
        <el-table-column align="center" prop="userType" label="类型">
        </el-table-column>
        <el-table-column align="center" prop="userJob" label="工种">
        </el-table-column>
        <el-table-column align="center" prop="phone" label="联系方式">
        </el-table-column>
        <el-table-column align="center" prop="birth" label="出生日期">
        </el-table-column>
        <el-table-column align="center" prop="workDate" label="参加工作日期">
        </el-table-column>
        <el-table-column align="center" prop="nation" label="民族">
        </el-table-column>
        <el-table-column align="center" prop="politicalStatus" label="政治面貌">
        </el-table-column>
        <el-table-column align="center" prop="partyDate" label="入党（团）日期">
        </el-table-column>
        <el-table-column align="center" prop="nativePlace" label="籍贯">
        </el-table-column>
        <el-table-column align="center" prop="address" label="户籍地址">
        </el-table-column>
        <el-table-column align="center" prop="bloodType" label="血型">
        </el-table-column>

        <el-table-column align="center" prop="onStatus" label="是否在位">
        </el-table-column>
      </el-table>
      <pagination
        v-show="BtnTotal > 0"
        :total="BtnTotal"
        :page.sync="queryParamsBtn.pageNum"
        :limit.sync="queryParamsBtn.pageSize"
        @pagination="getAnUserList()" />
    </el-dialog>

    <!-- 点击列表 -->
    <el-dialog
      title="人员信息"
      custom-class="dialog-list"
      :visible.sync="dialogVisibleTable"
      width="60%"
      @close="closeListDialog"
      append-to-body>
      <el-table
        :data="dialogTableData"
        style="width: 100%"
        stripe
        height="540"
        :header-cell-style="{
          background: '#3c598c',
          color: 'rgb(220, 231, 255);'
        }">
        <el-table-column align="center" prop="userName" label="姓名">
        </el-table-column>
        <el-table-column align="center" prop="deptName" label="部门">
        </el-table-column>
        <el-table-column align="center" prop="userPosition" label="职务职级">
        </el-table-column>
        <el-table-column
          align="center"
          prop="userPositionDate"
          label="任职时间">
        </el-table-column>
        <el-table-column align="center" prop="userType" label="类型">
        </el-table-column>
        <el-table-column align="center" prop="userJob" label="工种">
        </el-table-column>
        <el-table-column align="center" prop="phone" label="联系方式">
        </el-table-column>
        <el-table-column align="center" prop="birth" label="出生日期">
        </el-table-column>
        <el-table-column align="center" prop="workDate" label="参加工作日期">
        </el-table-column>
        <el-table-column align="center" prop="nation" label="民族">
        </el-table-column>
        <el-table-column align="center" prop="politicalStatus" label="政治面貌">
        </el-table-column>
        <el-table-column align="center" prop="partyDate" label="入党（团）日期">
        </el-table-column>
        <el-table-column align="center" prop="nativePlace" label="籍贯">
        </el-table-column>
        <el-table-column align="center" prop="address" label="户籍地址">
        </el-table-column>
        <el-table-column align="center" prop="bloodType" label="血型">
        </el-table-column>
        <el-table-column align="center" prop="onStatus" label="是否在位">
        </el-table-column>
      </el-table>
      <pagination
        v-show="total > 0"
        :total="total"
        :page.sync="queryParams.pageNum"
        :limit.sync="queryParams.pageSize"
        @pagination="getAnUserInfo()" />
    </el-dialog>
  </div>
</template>

<script>
import {
  anUserStatistic,
  all,
  anUserInfo,
  userDeptName,
  queryUserType,
  queryUserJob,
  anUserList
} from '../api/test'

export default {
  data() {
    return {
      total: 0,
      // 查询参数
      queryParams: {
        pageNum: 1,
        pageSize: 10,
        deptId: null,
        userType: null,
        userJob: null
      },
      allPeople: null,
      tableData: [],
      dialogTableData: [],
      dialogVisibleTable: false,
      titleBtn: '',
      indexBtn: 0,
      userType: null,
      userJob: null,
      deptId: null,
      // 按钮弹窗------------------
      dialogVisibleBtn: false,
      btnDialogTableData: [],
      queryParamsBtn: {
        pageNum: 1,
        pageSize: 10,
        deptId: null,
        largeType: null,
        smallType: null
      },
      BtnTotal: 0,
      pianquOptions: [],
      daleiOptions: [],
      xiaoleiOptions: []
    }
  },
  mounted() {
    this.getAll()
    this.getAnUserStatistic()
  },
  methods: {
    // 片区列表
    getAnUserStatistic() {
      anUserStatistic().then((res) => {
        if (res.data.data) {
          this.tableData = res.data.data
        }
      })
    },
    getAll() {
      all().then((res) => {
        if (res.data.data) {
          this.allPeople = res.data.data
        }
      })
    },
    getAnUserInfo() {
      this.queryParams.deptId = this.deptId
      this.queryParams.userType = this.userType
      this.queryParams.userJob = this.userJob
      anUserInfo(this.queryParams).then((res) => {
        // console.log(res)
        this.dialogTableData = res.data.rows
        this.total = res.data.total
      })
    },
    // 点击列表
    handleSeleUserId(e) {
      // console.log(c)
      this.dialogVisibleTable = true
      if (e.level == 2) {
        this.userType = e.typeName
        this.userJob = ''
      }
      if (e.level == 3) {
        this.tableData.forEach((e1) => {
          e1.children.forEach((e2) => {
            e2.children.forEach((e3) => {
              if (e3.typeName == e.typeName) {
                this.userType = e2.typeName
                this.userJob = e.typeName
              }
            })
          })
        })
      }
      this.deptId = e.deptId
      this.getAnUserInfo()
    },
    //按钮弹窗------------------------------
    // 按钮
    changeBtn() {
      this.dialogVisibleBtn = true
      this.getClassify()
      this.getQueryUserJob()
      this.getUserType()
      this.getAnUserList()
    },
    // 部门下拉
    getClassify() {
      userDeptName().then((res) => {
        this.pianquOptions = res.data.data
      })
    },
    // 分类下拉
    getUserType() {
      queryUserType().then((res) => {
        this.daleiOptions = res.data.data
      })
    },
    // 小类下拉
    getQueryUserJob() {
      queryUserJob().then((res) => {
        this.xiaoleiOptions = res.data.data
      })
    },
    // 装备弹窗列表
    getAnUserList() {
      anUserList(this.queryParamsBtn).then((res) => {
        // console.log(res, 'pppp')
        if (res.data.rows) {
          this.btnDialogTableData = res.data.rows
          this.BtnTotal = res.data.total
        }
      })
    },
    //装备弹窗列表搜索
    handleQuery() {
      this.queryParamsBtn.pageNum = 1
      this.getAnUserList()
    },
    resetQuery() {
      this.queryParamsBtn = {
        pageNum: 1,
        pageSize: 10,
        deptId: null,
        largeType: null,
        smallType: null
      }
      this.getAnUserList()
    },
    // 关闭按钮弹窗
    closeBtnDialog() {
      this.queryParamsBtn.pageNum = 1
      this.dialogVisibleBtn = false
    },
    // 关闭列表弹窗
    closeListDialog() {
      this.queryParams.pageNum = 1
      this.dialogVisibleTable = false
    }
  }
}
</script>

<style lang="scss" scoped>
$box-width: 400px;
$box-height: 950px;

#centerLeft1 {
  height: $box-height;
  width: $box-width;
  .top-header {
    position: relative;
    width: 386px;
    height: 48px;
    background-image: url('../assets/images/top_header.svg');
    // background-size: 100% 100%;
    font-size: 20px;
    padding-left: 35px;
    padding-top: 15px;
    margin-bottom: 19px;
    & > span {
      background-image: -webkit-linear-gradient(bottom, #f9fbfe, #afe4fe);
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
    }
    .chaxun {
      width: 60px;
      height: 34px;
      cursor: pointer;
      // border: 1px solid red;
      background-image: url('../assets/images/btn.svg');
      background-size: 100% 100%;
      position: absolute;
      right: 5px;
      top: 0px;
    }
  }
  .people-tongji {
    display: flex;
    .people-tongji-right {
      .right-top {
        position: relative;
        .position-word {
          font-size: 20px;
          color: #fff;
          position: absolute;
          top: 8px;
          left: 37px;
        }
        .position-img {
          position: absolute;
          top: 12px;
          left: 187px;
        }
      }
      .right-bottom {
        margin-top: 5px;
        margin-left: 23px;
        background-color: #213b71;
        width: 254px;
        height: 40px;
        color: #a3b5d7;
        line-height: 40px;
        .the-word {
          margin-left: 27px;
        }
        .the-num {
          color: #6abeff;
          margin-left: 50px;
        }
      }
    }
  }
  // .select {
  // margin-top: 16px;

  // }
  .bottom-table {
    margin-top: 14px;
    border-top: 2px solid #6187e4;
    width: 400px;
    // height: 760px;
    // overflow: auto;
    ::v-deep .el-table .el-table__cell {
      padding: 7px 0 !important;
    }
    ::v-deep .el-table,
    ::v-deep .el-table__expanded-cell {
      background-color: transparent;
    }
    /* 表格内背景颜色 */
    ::v-deep .el-table th,
    ::v-deep .el-table tr,
    ::v-deep .el-table td {
      color: rgb(183, 210, 241);
      background-color: transparent;
    }
    // 背景色
    ::v-deep .el-table tr {
      background-color: #131e34;
      // background-color: red;
    }
    // 表格分开
    ::v-deep .el-table__body {
      //-webkit-border-horizontal-spacing: 13px;  // 水平间距
      -webkit-border-vertical-spacing: 10px; // 垂直间距
    }
    // 隔行变色
    ::v-deep .el-table--striped {
      .el-table__body tr.el-table__row--striped td.el-table__cell {
        background-color: #0d172a;
      }
    }
    ::v-deep .el-table td.el-table__cell,
    .el-table th.el-table__cell.is-leaf {
      border-bottom: 1px solid #243a64;
    }
    // hover
    ::v-deep
      .el-table--enable-row-hover
      .el-table__body
      tr:hover
      > td.el-table__cell {
      background-color: unset;
    }
    // 箭头
    ::v-deep .el-icon-arrow-right:before {
      color: rgb(183, 210, 241);
    }
    // header   border-bottom
    ::v-deep .el-table th.el-table__cell.is-leaf {
      border-bottom: 0;
    }
    //最底部横线
    ::v-deep .el-table::before {
      height: 0;
    }
  }
}
::v-deep.el-table--scrollable-y .el-table__body-wrapper::-webkit-scrollbar {
  width: 6px;
  height: 50px;
  background-color: rgb(183, 210, 241);
}
::v-deep.el-table--scrollable-y
  .el-table__body-wrapper::-webkit-scrollbar-thumb {
  border-radius: 10px;
  // -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  background-color: #00aaff;
}
.dialog-footer {
  text-align: center;
}
.btns {
  display: flex;
  font-size: 18px;
  font-weight: 600;
  margin-top: 15px;
  .btn {
    background-color: #0e244c;
    color: #c9dff7;
    width: 98px;
    height: 30px;
    line-height: 28px;
    text-align: center;
    cursor: pointer;
    border: 1px solid #4991d1;
  }
  .btn:hover {
    background-image: linear-gradient(0deg, #1d4c9a, #143772);
    color: #c4d7ee;
  }
}
// jjjjjjjjjjjjjjjjjjjjjjjjjjjjjjjjjjjjjjjjjjjjjjjjjjjjjjj
::v-deep .el-table .el-table__cell {
  padding: 9px 0 !important;
}
::v-deep .el-table,
::v-deep .el-table__expanded-cell {
  background-color: transparent;
}
/* 表格内背景颜色 */
::v-deep .el-table th,
::v-deep .el-table tr,
::v-deep .el-table td {
  color: rgb(183, 210, 241);
  background-color: transparent;
}
::v-deep .el-table tr {
  background-color: #131e34;
  // background-color: red;
}
// 隔行变色
::v-deep .el-table--striped {
  .el-table__body tr.el-table__row--striped td.el-table__cell {
    background-color: #0d172a;
    // background-color: red;
  }
}
::v-deep .el-table td.el-table__cell,
.el-table th.el-table__cell.is-leaf {
  border-bottom: 1px solid #243a64;
}
// hover
::v-deep
  .el-table--enable-row-hover
  .el-table__body
  tr:hover
  > td.el-table__cell {
  background-color: unset;
}
::v-deep.el-table--scrollable-y .el-table__body-wrapper::-webkit-scrollbar {
  width: 6px;
  height: 6px;
  background-color: rgb(183, 210, 241);
}
::v-deep.el-table--scrollable-y
  .el-table__body-wrapper::-webkit-scrollbar-thumb {
  border-radius: 10px;
  // -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  background-color: #00aaff;
}

///@at-root
///
///
::v-deep .el-form-item__label {
  color: rgb(183, 210, 241);
}

//@at-root
::v-deep .el-input__inner {
  border: 1px solid #2094fb;
  // border: 1px solid red;
  border-radius: 0;
  background-color: #12253d;
  color: #fff;
}
::v-deep .el-select-dropdown {
  background-color: #12253d !important;
  border: 0;
}
::v-deep .el-select-dropdown__item {
  color: #fff;
}
::v-deep .el-select-dropdown__item.selected {
  color: #afe4fe;
}
::v-deep .el-select-dropdown__item.hover,
.el-select-dropdown__item:hover {
  background-color: #2094fb;
}
::v-deep .el-popper[x-placement^='bottom'] .popper__arrow::after {
  background-color: #12253d;
}
::v-deep .el-color-picker__icon,
.el-input,
.el-textarea {
  // width: 150px !important;
}
::v-deep .el-table .el-table__cell.gutter {
  background: rgb(40, 68, 137) !important;
}
::v-deep .dialog-list {
  background-color: #0e2241;
  width: 75% !important;
  height: 694px !important;
  margin-top: 10vh !important;
  .el-dialog__title {
    color: #fff;
  }
  .el-dialog__header {
    padding: 0 0 0 58px;
    height: 50px;
    line-height: 50px;

    background-image: url('../assets/images/list-top.svg');
    background-repeat: no-repeat;
    background-position: 20px 10px;
    // background-size: 100% 100%;
    background-color: #2a4e93;
  }
  .el-dialog__body {
    padding: 20px 38px;
    height: calc(100% - 55px);
    overflow: auto;
    .el-table .el-table__cell.gutter {
      background: #3c598c !important;
    }
  }
}
</style>
