<template>
  <div id="index" ref="appRef">
    <div class="bg">
      <dv-loading v-if="loading">Loading...</dv-loading>
      <div v-else class="host-body">
        <weather :isShow="true" />
        <div class="body-box">
          <!-- 第三行数据 -->
          <div class="content-box">
            <div>
              <centerLeft1 />
            </div>
            <div>
              <centerLeft2 />
            </div>
            <div>
              <div class="box-margin">
                <centerRight1></centerRight1>
              </div>
              <div style="margin-top: 15px">
                <centerRight2> </centerRight2>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import drawMixin from '../utils/drawMixin'
import centerLeft1 from './centerLeft1'
import centerLeft2 from './centerLeft2'
import centerRight1 from './centerRight1'
import centerRight2 from './centerRight2'
import weather from './components/weather'

export default {
  mixins: [drawMixin],
  components: {
    centerLeft1,
    centerLeft2,
    centerRight1,
    centerRight2,
    weather
    // pie
  },
  data() {
    return {
      loading: true
    }
  },
  provide() {
    return { isShow: false }
  },
  mounted() {
    this.cancelLoading()
  },
  beforeDestroy() {},
  methods: {
    cancelLoading() {
      setTimeout(() => {
        this.loading = false
      }, 500)
    }
  }
}
</script>

<style lang="scss" scoped>
@import '../assets/scss/index.scss';
.content-box {
  margin-top: -22px;
}
</style>
