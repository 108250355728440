<template>
  <div id="centerRight1">
    <div class="top-header"><div class="chaxun" @click="changeBtn"></div></div>
    <div class="box-pie">
      <rightBottom :cata="data" />
      <div class="center-circle">
        <div class="word">物资总数</div>
        <div class="num">{{ dataNum }}</div>
      </div>
    </div>
    <div class="box-bottom">
      <div
        class="bottom-one"
        v-for="item in dataList"
        :key="item.deptName"
        @click="getMaterialDetail(item.deptId)">
        <div class="name" :title="item.deptName">{{ item.deptName }}</div>
        <div class="progress">
          <el-progress
            :percentage="getPercentage(item.totalNum)"
            :show-text="false"></el-progress>
        </div>
        <div>
          {{ item.totalNum }}
        </div>
      </div>
    </div>
    <el-dialog
      title="物资情况"
      :visible.sync="openInfo"
      width="60%"
      custom-class="dialog-wz"
      append-to-body>
      <div class="dialog-content">
        <div class="zongshu">{{ detailDataList.totalNum }}台套</div>
        <div class="list">
          <div
            class="list-one"
            v-for="item in detailDataList.dataList"
            :key="item.name">
            <div class="title">
              <div class="title-left"></div>
              <div class="title-word">{{ item.name + item.num }}台套</div>
            </div>
            <div class="children-list">
              <div
                class="bottom-one"
                v-for="i in item.children"
                :key="i.name"
                @click="zbDialog(item, i)">
                <div class="name" :title="i.name">{{ i.name }}</div>
                <div class="progress">
                  <el-progress
                    :percentage="(i.num / item.num) * 100"
                    :show-text="false"></el-progress>
                </div>
                <div>
                  {{ i.num }}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </el-dialog>
    <el-dialog
      :title="chuantouTitle"
      :visible.sync="chuantouInfo"
      @close="closeListDialog"
      width="60%"
      custom-class="dialog-list"
      append-to-body>
      <el-table
        :data="dialogTableData"
        style="width: 100%"
        stripe
        height="540"
        :header-cell-style="{
          background: '#3c598c',
          color: 'rgb(220, 231, 255);'
        }">
        <el-table-column align="center" prop="deptName" label="部门">
        </el-table-column>
        <el-table-column align="center" prop="largeType" label="大类">
        </el-table-column>
        <el-table-column align="center" prop="mediumType" label="中类">
        </el-table-column>
        <el-table-column align="center" prop="materialName" label="名称">
        </el-table-column>
        <el-table-column align="center" prop="materialModel" label="规格型号">
        </el-table-column>
        <el-table-column align="center" prop="number" label="数量">
        </el-table-column>
        <el-table-column align="center" prop="measureUnit" label="计量单位">
        </el-table-column>
        <el-table-column align="center" prop="inDate" label="入库日期">
        </el-table-column>
        <el-table-column
          align="center"
          prop="warehouseAdmin"
          label="库室管理负责人" />
        <!-- </el-table> -->
        <el-table-column
          align="center"
          prop="warehouseLocation"
          label="库室位置" />
        <!-- </el-table> -->
        <el-table-column align="center" prop="useInfo" label="状态" />
      </el-table>
      <pagination
        v-show="total > 0"
        :total="total"
        :page.sync="queryParams.pageNum"
        :limit.sync="queryParams.pageSize"
        @pagination="geteMaterialInfo()" />
    </el-dialog>

    <!-- 按钮 -->
    <el-dialog
      title="物资详情"
      :visible.sync="dialogVisibleBtn"
      width="60%"
      custom-class="dialog-list"
      @close="closeBtnDialog"
      append-to-body>
      <el-form
        :model="queryParamsBtn"
        ref="queryForm"
        size="small"
        :inline="true"
        label-width="50px">
        <el-form-item label="部门" prop="deptName">
          <el-select
            v-model="queryParamsBtn.deptName"
            placeholder="请选择"
            :popper-append-to-body="false">
            <el-option
              v-for="item in pianquOptions"
              :key="item.deptName"
              :label="item.deptName"
              :value="item.deptName">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="大类" prop="largeType">
          <el-select
            v-model="queryParamsBtn.largeType"
            placeholder="请选择"
            :popper-append-to-body="false">
            <el-option
              v-for="item in daleiOptions"
              :key="item.largeType"
              :label="item.largeType"
              :value="item.largeType">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="中类" prop="mediumType">
          <el-select
            v-model="queryParamsBtn.mediumType"
            placeholder="请选择"
            :popper-append-to-body="false">
            <el-option
              v-for="item in zhongleiOptions"
              :key="item.mediumType"
              :label="item.mediumType"
              :value="item.mediumType">
            </el-option>
          </el-select>
        </el-form-item>
        <!-- <el-form-item label="小类" prop="smallType">
          <el-select
            v-model="queryParamsBtn.smallType"
            placeholder="请选择"
            :popper-append-to-body="false">
            <el-option
              v-for="item in xiaoleiOptions"
              :key="item.smallType"
              :label="item.smallType"
              :value="item.smallType">
            </el-option>
          </el-select>
        </el-form-item> -->
        <el-form-item>
          <el-button
            type="primary"
            icon="el-icon-search"
            size="mini"
            @click="handleQuery"
            >搜索</el-button
          >
          <el-button
            type="primary"
            icon="el-icon-refresh"
            size="mini"
            @click="resetQuery"
            >重置</el-button
          >
        </el-form-item>
      </el-form>
      <el-table
        :data="btnDialogTableData"
        style="width: 100%"
        stripe
        height="475"
        :header-cell-style="{
          background: '#3c598c',
          color: 'rgb(220, 231, 255);'
        }">
        <el-table-column align="center" prop="deptName" label="部门">
        </el-table-column>
        <el-table-column align="center" prop="largeType" label="大类">
        </el-table-column>
        <el-table-column align="center" prop="mediumType" label="中类">
        </el-table-column>
        <el-table-column align="center" prop="materialName" label="名称">
        </el-table-column>
        <el-table-column align="center" prop="materialModel" label="规格型号">
        </el-table-column>
        <el-table-column align="center" prop="number" label="数量">
        </el-table-column>
        <el-table-column align="center" prop="measureUnit" label="计量单位">
        </el-table-column>
        <el-table-column align="center" prop="inDate" label="入库日期">
        </el-table-column>
        <el-table-column
          align="center"
          prop="warehouseAdmin"
          label="库室管理负责人" />
        <!-- </el-table> -->
        <el-table-column
          align="center"
          prop="warehouseLocation"
          label="库室位置" />
        <!-- </el-table> -->
        <el-table-column align="center" prop="useInfo" label="状态" />
      </el-table>

      <pagination
        v-show="BtnTotal > 0"
        :total="BtnTotal"
        :page.sync="queryParamsBtn.pageNum"
        :limit.sync="queryParamsBtn.pageSize"
        @pagination="getMaterialList()" />
    </el-dialog>
  </div>
</template>

<script>
import rightBottom from '@/components/echart/right/rightBottom'
import {
  materialTota,
  materialDetail,
  eMaterialInfo,
  materialDeptName,
  materialLargeType,
  materialMediumType,
  materialList
} from '../api/test'

export default {
  components: {
    rightBottom
  },
  props: {},
  data() {
    return {
      total: 0,
      // 查询参数
      queryParams: {
        pageNum: 1,
        pageSize: 10,
        deptId: null,
        largeType: null,
        smallType: null
      },
      chuantouTitle: '',
      chuantouInfo: false,
      data: [],
      dataList: [
        { deptName: '本部及dadadnannnnnnnnnnnnn基地1', totalNum: 200 }
      ],
      dataNum: 300,
      detailDataList: {
        totalNum: 5000,
        dataList: [
          {
            name: '运输类',
            num: 500,
            children: [{ name: '拖车(台套)2', num: 30 }]
          }
        ]
      },
      openInfo: false,
      dialogTableData: [],
      deptId: null,
      largeType: null,
      mediumType: null,

      // 按钮弹窗------------------
      dialogVisibleBtn: false,
      btnDialogTableData: [],
      queryParamsBtn: {
        pageNum: 1,
        pageSize: 10,
        deptId: null,
        largeType: null,
        smallType: null,
        mediumType: null
      },
      BtnTotal: 0,
      pianquOptions: [],
      daleiOptions: [],
      zhongleiOptions: [],
      xiaoleiOptions: []
    }
  },
  mounted() {
    this.getMaterialTota()
  },
  methods: {
    getPercentage(num) {
      return (num / this.dataNum) * 100
    },
    getMaterialTota() {
      materialTota().then((res) => {
        this.dataList = res.data.data
        res.data.data.forEach((e) => {
          this.dataNum += e.totalNum
        })
      })
    },
    getMaterialDetail(id) {
      this.openInfo = true
      this.deptId = id
      materialDetail({ deptId: id }).then((res) => {
        this.detailDataList = res.data.data
      })
    },
    //穿透点击
    zbDialog(item, i) {
      this.chuantouTitle = item.name
      this.chuantouInfo = true
      console.log(item)
      this.largeType = item.name
      this.mediumType = i.name
      this.geteMaterialInfo()
    },
    geteMaterialInfo() {
      this.queryParams.deptId = this.deptId
      this.queryParams.largeType = this.largeType
      this.queryParams.mediumType = this.mediumType
      eMaterialInfo(this.queryParams).then((res) => {
        this.dialogTableData = res.data.rows
        this.total = res.data.total
      })
    },

    //按钮弹窗------------------------------
    // 按钮
    changeBtn() {
      this.dialogVisibleBtn = true
      this.getClassify()
      this.getMaterialLargeType()
      this.getMaterialMediumType()
      this.getMaterialList()
    },
    // 片区下拉
    getClassify() {
      materialDeptName().then((res) => {
        this.pianquOptions = res.data.data
      })
    },
    // 大类下拉
    getMaterialLargeType() {
      materialLargeType().then((res) => {
        this.daleiOptions = res.data.data
      })
    },
    // 中类下拉
    getMaterialMediumType() {
      materialMediumType().then((res) => {
        this.zhongleiOptions = res.data.data
      })
    },
    // 小类下拉
    // getMaterialSmallType() {
    //   materialSmallType().then((res) => {
    //     this.xiaoleiOptions = res.data.data
    //   })
    // },
    // 装备弹窗列表
    getMaterialList() {
      materialList(this.queryParamsBtn).then((res) => {
        if (res.data.rows) {
          this.btnDialogTableData = res.data.rows
          this.BtnTotal = res.data.total
        }
      })
    },
    //装备弹窗列表搜索
    handleQuery() {
      this.queryParamsBtn.pageNum = 1
      this.getMaterialList()
    },
    resetQuery() {
      this.queryParamsBtn = {
        pageNum: 1,
        pageSize: 10,
        deptId: null,
        largeType: null,
        smallType: null
      }
      this.getMaterialList()
    },
    // 关闭按钮弹窗
    closeBtnDialog() {
      this.queryParamsBtn.pageNum = 1
      this.dialogVisibleBtn = false
    },
    // 关闭列表弹窗
    closeListDialog() {
      this.queryParams.pageNum = 1
      this.chuantouInfo = false
    }
  }
}
</script>

<style lang="scss" scoped>
$box-width: 443px;
$box-height: 475px;
#centerRight1 {
  height: $box-height;
  width: $box-width;
  .top-header {
    position: relative;

    width: 433px;
    height: 48px;
    background-image: url('../assets/images/wzheader.svg');
    background-size: 100% 100%;
    font-size: 20px;
    padding-left: 25px;
    padding-top: 9px;
    .chaxun {
      width: 60px;
      height: 34px;
      cursor: pointer;
      background-image: url('../assets/images/btn.svg');
      background-size: 100% 100%;
      position: absolute;
      right: 5px;
      top: 0px;
    }
  }
  .box-pie {
    position: relative;
    width: 100%;
    .center-circle {
      position: absolute;
      width: 150px;
      height: 150px;
      border-radius: 50%;
      background-image: url('../assets/wuzicircle.svg');
      background-size: 100% 100%;
      top: 52px;
      left: 103px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      z-index: -1;
      .word {
        color: rgba(255, 255, 255, 0.7);
      }
      .num {
        font-size: 20px;
        margin-top: 11px;
      }
    }
  }
  .box-bottom {
    display: flex;
    flex-direction: column;
    // align-items: ;
    justify-content: space-between;
    width: 100%;
    height: 180px;
    // background-color: #fff;
    .bottom-one {
      display: flex;
      align-items: center;
      // margin-top: 28px;
      color: #fff;
      cursor: pointer;
      .name {
        width: 100px;
        text-align: right;
        margin-right: 8px;
        color: rgba(176, 202, 232, 1);
        overflow: hidden; // 溢出隐藏
        white-space: nowrap; // 强制一行
        text-overflow: ellipsis; // 文字溢出显示省略号
      }
      .progress {
        margin-right: 8px;
        .el-progress {
          width: 300px;
        }
      }
    }
  }
}
::v-deep .dialog-wz {
  // background-image: url('../assets/tcbg.png');
  background-size: 100% 100%;
  background-color: #0e2241;
  width: 1105px !important;
  height: 694px !important;
  margin-top: 10vh !important;

  .el-dialog__title {
    color: #fff;
  }
  .el-dialog__header {
    padding: 0 0 0 58px;
    height: 50px;
    line-height: 50px;

    background-image: url('../assets/images/list-top.svg');
    background-repeat: no-repeat;
    background-position: 20px 10px;
    // background-size: 100% 100%;
    background-color: #2a4e93;
  }
  .el-dialog__body {
    padding: 20px 38px;
    height: calc(100% - 55px);
    overflow: auto;
  }
  .dialog-content {
    .zongshu {
      font-size: 16px;
      color: #fff;
    }
    .list {
      display: flex;
      flex-wrap: wrap;
      margin-top: 15px;
      .list-one {
        width: 510px;
        // background-color: green;
        margin-bottom: 32px;

        .title {
          display: flex;
          align-items: center;
          color: #fff;
          .title-left {
            width: 2px;
            height: 21px;
            background-color: #fff;
            margin-right: 11px;
          }
          .title-word {
            font-size: 18px;
          }
        }
        .children-list {
          margin-top: 25px;
          height: 220px;
          display: flex;
          flex-direction: column;
          // align-items: ;
          justify-content: space-between;
          overflow: auto;
          .bottom-one {
            display: flex;
            align-items: center;
            color: #fff;
            cursor: pointer;
            // margin-top: 28px;
            .name {
              width: 125px;
              text-align: right;
              margin-right: 8px;
              color: rgba(176, 202, 232, 1);
              overflow: hidden; // 溢出隐藏
              white-space: nowrap; // 强制一行
              text-overflow: ellipsis; // 文字溢出显示省略号
            }
            .progress {
              margin-right: 8px;
              .el-progress {
                width: 320px;
              }
            }
          }
        }
      }
      .list-one:nth-last-child(1),
      .list-one:nth-last-child(2) {
        margin-bottom: 0;
      }
    }
  }
}
.children-list::-webkit-scrollbar {
  width: 6px;
  height: 50px;
  background-color: rgb(183, 210, 241);
}
.children-list::-webkit-scrollbar-thumb {
  border-radius: 10px;
  // -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  background-color: #00aaff;
}
/* 渐变进度条 */
::v-deep .el-progress-bar__inner {
  background-image: linear-gradient(to top, #facf23, #ff5a49);
  border-radius: 0;
}

::v-deep .el-progress-bar__outer {
  background-color: rgba(255, 255, 255, 0.08) !important;
  border-radius: 0;
}
::v-deep .el-dialog__body::-webkit-scrollbar {
  width: 6px;
  height: 50px;
  background-color: rgb(183, 210, 241);
}
::v-deep .el-dialog__body::-webkit-scrollbar-thumb {
  border-radius: 10px;
  // -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  background-color: #00aaff;
}

// jjjjjjjjjjjjjjjjjjjjjjjjjjjjjjjjjjjjjjjjjjjjjjjjjjjjjjj
::v-deep .el-table .el-table__cell {
  padding: 9px 0 !important;
}
::v-deep .el-table,
::v-deep .el-table__expanded-cell {
  background-color: transparent;
}
/* 表格内背景颜色 */
::v-deep .el-table th,
::v-deep .el-table tr,
::v-deep .el-table td {
  color: rgb(183, 210, 241);
  background-color: transparent;
}
::v-deep .el-table tr {
  background-color: #131e34;
  // background-color: red;
}
// 隔行变色
::v-deep .el-table--striped {
  .el-table__body tr.el-table__row--striped td.el-table__cell {
    background-color: #0d172a;
    // background-color: red;
  }
}
::v-deep .el-table td.el-table__cell,
.el-table th.el-table__cell.is-leaf {
  border-bottom: 1px solid #243a64;
}
// hover
::v-deep
  .el-table--enable-row-hover
  .el-table__body
  tr:hover
  > td.el-table__cell {
  background-color: unset;
}
::v-deep.el-table--scrollable-y .el-table__body-wrapper::-webkit-scrollbar {
  width: 6px;
  height: 50px;
  background-color: rgb(183, 210, 241);
}
::v-deep.el-table--scrollable-y
  .el-table__body-wrapper::-webkit-scrollbar-thumb {
  border-radius: 10px;
  // -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  background-color: #00aaff;
}

///@at-root
///
///
::v-deep .el-form-item__label {
  color: rgb(183, 210, 241);
}

//@at-root
::v-deep .el-input__inner {
  border: 1px solid #2094fb;
  // border: 1px solid red;
  border-radius: 0;
  background-color: #12253d;
  color: #fff;
}
::v-deep .el-select-dropdown {
  background-color: #12253d !important;
  border: 0;
}
::v-deep .el-select-dropdown__item {
  color: #fff;
}
::v-deep .el-select-dropdown__item.selected {
  color: #afe4fe;
}
::v-deep .el-select-dropdown__item.hover,
.el-select-dropdown__item:hover {
  background-color: #2094fb;
}
::v-deep .el-popper[x-placement^='bottom'] .popper__arrow::after {
  background-color: #12253d;
}
::v-deep .dialog-list {
  background-color: #0e2241;
  width: 75% !important;
  height: 694px !important;
  margin-top: 10vh !important;
  .el-dialog__title {
    color: #fff;
  }
  .el-dialog__header {
    padding: 0 0 0 58px;
    height: 50px;
    line-height: 50px;

    background-image: url('../assets/images/list-top.svg');
    background-repeat: no-repeat;
    background-position: 20px 10px;
    // background-size: 100% 100%;
    background-color: #2a4e93;
  }
  .el-dialog__body {
    padding: 20px 38px;
    height: calc(100% - 55px);
    overflow: auto;
    .el-table .el-table__cell.gutter {
      background: #3c598c !important;
    }
  }
}
</style>
