<template>
  <div>
    <Echart
      :options="options"
      id="rightTop"
      height="250px"
      width="100%"></Echart>
  </div>
</template>

<script>
import Echart from '@/common/echart'
import { equipTotal } from '@/api/test'

export default {
  data() {
    return {
      options: {},
      data1: []
    }
  },
  components: {
    Echart
  },
  props: {
    cdata: {
      type: Array,
      default: () => []
    }
  },
  watch: {
    data1: {
      handler(data) {
        console.log(data, 'oooo')
        let total = null
        data.forEach((e) => {
          total += e.value
        })
        this.options = {
          tooltip: {
            trigger: 'item'
          },
          // grid: {
          //   left: '5%',
          //   bottom: 50,
          //   containLabel: true
          // },
          legend: {
            orient: 'vertical',
            itemHeight: 8,
            itemWidth: 8,
            itemGap: 25, //图例之间的间距
            left: '70%',
            x: 'left', //可设定图例在左、右、居中
            y: 'center', //可设定图例在上、下、居中
            padding: [0, 50, 0, 0], //可设定图例[距上方距离，距右方距离，距下方距离，距左方距离]
            formatter: function (name) {
              console.log(name)
              let singleData = data.filter(function (item) {
                return item.name == name
              })
              return (
                name +
                '\t\t\t\t' +
                ((singleData[0].value / total) * 100).toFixed(2) +
                '%'
              )
            },
            textStyle: {
              rich: {
                a: {
                  width: 100,
                  fontSize: 12,
                  lineHeight: 18
                }
              }
            }
          },
          series: [
            {
              name: '装备情况',
              type: 'pie',
              radius: ['55%', '70%'],
              avoidLabelOverlap: false,
              itemStyle: {
                borderRadius: 0,
                borderColor: '#061830',
                borderWidth: 5
              },
              center: ['40%', '50%'],
              label: {
                show: false
                // position: 'center'
              },
              labelLine: {
                show: false
              },
              data: data
            }
            // {
            //   name: '',
            //   type: 'pie',
            //   selectedMode: 'single',
            //   radius: [0, '45%'],
            //   // label: {
            //   //   position: 'inner',
            //   //   fontSize: 14,

            //   // },
            //   label: {
            //     normal: {
            //       show: true,
            //       position: 'center'
            //     }
            //   },
            //   labelLine: {
            //     show: false
            //   },
            //   data: [1500]
            // }
          ]
        }
      },
      // immediate: true,
      deep: true
    }
  },
  mounted() {
    this.getEquipTotal()
  },
  methods: {
    getEquipTotal() {
      equipTotal().then((res) => {
        // console.log(res)
        // this.dataList = res.data.data
        // res.data.data.forEach((e) => {
        //   this.dataNum += e.totalNum
        // })
        this.data1 = res.data.data.map((e) => {
          return {
            name: e.deptName,
            value: e.totalNum
          }
        })
        // console.log(this.data, 'pppp')
      })
    }
  }
}
</script>

<style lang="scss" scoped></style>
